import Player from "@madzadev/audio-player";
//import Player from "./components/audio-player/Player";
import "./styles/rockplayer.css";
import logo from './imgs/Logo4.png';

import Masquerade from './songs/Masquerade.mp3';
import PolarisAfternoon from './songs/PolarisAfternoon.mp3';
import PunkRockinDearborn from './songs/PunkRockinDearborn.mp3';
import JustAnotherDay from './songs/AnotherDay.mp3';
import HappyInTheDark from './songs/HappyInTheDark.mp3';
import CorporealGhostMastered4 from './songs/CorporealGhostMastered4.mp3';
import Victimizer9 from './songs/Victimizer9.mp3';
import TreMellow from './songs/TreMellow.mp3';
import BetterLuckNextTime from './songs/BetterLuckNextTime-Mastered-Final.mp3';
import BiteYourLip from './songs/BiteYourLip-v3.mp3';
import Blister from './songs/Blister-11-FINAL.mp3';
import BrianGarageRock2019 from './songs/BrianGarageRock2019-Ver6.mp3';
import Escalation from './songs/Escalation-Ver2_1.mp3';
import GlassJoe from './songs/GlassJoe-Final.mp3';
import GoodLuckPal from './songs/GoodLuckPal-Ver7.mp3';
import ImminentCatharsis from './songs/ImminentCatharsis-MasteredFinal.mp3';
import MagnumOpus from './songs/MagnumOpus-Mixed4-Top40ExciterOn.mp3';
import MurderEverything from './songs/MurderEverything-Mastered1.mp3';
import RevengeOfTheSynth from './songs/RevengeOfTheSynth-MasteredFinal.mp3';
import SomethingsWrong from './songs/SomethingsWrong-Master11.mp3';
import ThrowBackAttack from './songs/ThrowBackAttack-BestMix1.mp3';
import WordsWithNoMeaning from './songs/WordsWithNoMeaning-Mastered5.mp3';
import WrecklessAbandon from './songs/WrecklessAbandon-Master2.mp3';

import DesperateTimes from './songs/DesperateTimes.mp3';
import ChippedTooth from './songs/ChippedTooth.mp3';
import AbsoluteHero from './songs/AbsoluteHero.mp3';
import SkankYaLater from './songs/SkankYaLater.mp3';
import TwoInThePunkOneInTheSkunk from './songs/TwoInThePunkOneInTheSkunk.mp3';
import UpInTheAir from './songs/UpInTheAir.mp3';
import WayBackWhen from './songs/WayBackWhen.mp3';

import Bipolarized from './songs/Bipolarized.mp3';
import LineInTheSand from './songs/LineInTheSand.mp3';
import AskMeLater from './songs/AskMeLater.mp3';
import MaybeSomeday from './songs/MaybeSomeday.mp3';


import './App.css';
  

function App() {

  const items = [/*'bg1','bg2','bg3','bg4',*/'bg5'/*,'bg6'*/];
  const bgnum = items[Math.floor(Math.random()*items.length)];
  document.body.classList.add(bgnum);
  //console.log(bgnum);
  const tracks = [
  {
    url: Masquerade,
    title: "012. A Masquerade",
    tags: ["Garage Rock"],
  },
  {
    url: PolarisAfternoon,
    title: "011. Polaris Afternoon",
    tags: ["Garage Rock"],
  },
  {
    url: PunkRockinDearborn,
    title: "022. Punk Rock in Dearborn",
    tags: ["Garage Rock"],
  },
  {
    url: JustAnotherDay,
    title: "013. Just Another Day",
    tags: ["Garage Rock"],
  },
  {
    url: BetterLuckNextTime,
    title: "014. Better Luck Next Time",
    tags: ["Garage Rock"],
  },
  {
    url: BiteYourLip,
    title: "010. Bite Your Lip",
    tags: ["Garage Rock"],
  },
  {
    url: Blister,
    title: "009. Blister",
    tags: ["Garage Rock"],
  },
  {
    url: BrianGarageRock2019,
    title: "015. Blood On the Dance Floor",
    tags: ["Garage Rock"],
  },
  {
    url: Escalation,
    title: "008. Escalation",
    tags: ["Garage Rock"],
  },
  {
    url: GlassJoe,
    title: "007. Glass Joe",
    tags: ["Garage Rock"],
  },
  {
    url: GoodLuckPal,
    title: "016. Good Luck Pal",
    tags: ["Garage Rock"],
  },
  {
    url: ImminentCatharsis,
    title: "022. Imminent Catharsis",
    tags: ["Garage Rock"],
  },
  {
    url: MagnumOpus,
    title: "006. Magnum Opus",
    tags: ["Garage Rock"],
  },
  {
    url: MurderEverything,
    title: "017. Murder Everything",
    tags: ["Garage Rock"],
  },
  {
    url: RevengeOfTheSynth,
    title: "021. Revenge Of The Synth",
    tags: ["Garage Rock"],
  },
  {
    url: SomethingsWrong,
    title: "005. Somethings Wrong",
    tags: ["Garage Rock"],
  },
  {
    url: ThrowBackAttack,
    title: "018. Throwback Attack",
    tags: ["Garage Rock"],
  },
  {
    url: WordsWithNoMeaning,
    title: "019. Words With No Meaning",
    tags: ["Garage Rock"],
  },
  {
    url: WrecklessAbandon,
    title: "004. Wreckless Abandon",
    tags: ["Garage Rock"],
  },
  {
    url: HappyInTheDark,
    title: "002. Happy In The Dark",
    tags: ["Garage Rock"],
  },
  {
    url: TreMellow,
    title: "020. TreMellow",
    tags: ["Garage Rock"],
  },
  {
    url: CorporealGhostMastered4,
    title: "001. Corporeal Ghost",
    tags: ["Garage Rock"],
  },
  {
    url: Victimizer9,
    title: "003. Victimizer",
    tags: ["Pop Punk"],
  },
  {
    url: DesperateTimes,
    title: "023. Desperate Times",
    tags: ["Garage Rock"],
  },
  {
    url: ChippedTooth,
    title: "024. Chipped Tooth",
    tags: ["Garage Rock"],
  },
  {
    url: AbsoluteHero,
    title: "025. Absolute Hero",
    tags: ["Garage Rock"],
  },
  {
    url: SkankYaLater,
    title: "026. Skank Ya Later",
    tags: ["Garage Rock"],
  },
  {
    url: TwoInThePunkOneInTheSkunk,
    title: "027. Two In The Punk One In The Skunk",
    tags: ["Garage Rock"],
  },
  {
    url: UpInTheAir,
    title: "028. Up In The Air",
    tags: ["Garage Rock"],
  },
  {
    url: WayBackWhen,
    title: "029. Way Back When",
    tags: ["Garage Rock"],
  },
  {
    url: Bipolarized,
    title: "030. Bipolarized",
    tags: ["Garage Rock"],
  },

  {
    url: LineInTheSand,
    title: "031. LineInTheSand",
    tags: ["Garage Rock"],
  },
  {
    url: AskMeLater,
    title: "032. AskMeLater",
    tags: ["Garage Rock"],
  },
  {
    url: MaybeSomeday,
    title: "033. MaybeSomeday",
    tags: ["Garage Rock"],
  },
];
  return (
    <>
    <div className="App">
      <header className="App-header">
        <span className="App-logo-wrapper">
        <img src={logo} className="App-logo" alt="logo" />
        </span>
        <h2>
          Nihlistic tunes, for 90's goons.
        </h2>
        {/*<a className="App-listen-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"> 
          <span className="material-icons">play_circle_filled</span><span>LISTEN</span>
        </a>*/}
      </header>
      <div className="rockPlayer">
        <Player trackList={tracks}
          includeTags={false}
          includeSearch={false}
          showPlaylist={true}
          autoPlayNextTrack={true}
         />
      </div>
    </div>
    </>
  );
}

export default App;
